import React from 'react';
import * as Scroll from 'react-scroll';

import Icon from '~/components/modules/icon';
import Button from '~/components/modules/button';
import SlideIn from '~/components/util/slide-in';

import BallBat from '~/images/inline/ball-bat.svg';

import site from '~/data/site';

const Footer = () => {
	let ScrollLink = Scroll.Link;

	return (
		<footer className="bg-green-150 overflow-hidden">
			<div className="relative container px-4 py-8 text-center">
				<SlideIn className="relative z-1">
					<Button
						as="a"
						to={site.entrySubmissionUrl}
						variant="secondary"
						icon="mdi:arrow-top-right"
					>
						Submit your entry
					</Button>

					<p className="mt-4">Contest open to students in grades 5 <span className="whitespace-nowrap">to 8.</span></p>
				</SlideIn>

				<BallBat
					className="w-48 absolute top-1/2 left-1/2 sm:left-0 -translate-y-1/2 -translate-x-1/2 sm:translate-x-0 text-white opacity-50 sm:opacity-100"
					aria-hidden="true"
					role="img"
				/>
			</div>

			<ScrollLink
				className="block relative z-1 px-4 py-2 text-center bg-black/10 text-white transition-colors hover:bg-blue-850 focus:bg-blue-850"
				href="#top"
				to="top"
				smooth={true}
				offset={-25}
				duration={250}
			>
				<span className="sr-only">To the top!</span>
				<Icon
					iconImage="mdi:arrow-collapse-up"
					sizeClasses="w-4 h-4 mx-auto"
				/>
			</ScrollLink>
		</footer>
	);
};

export default Footer;
